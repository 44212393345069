<template>
  <div class="container">
    <div class="content">
      <div class="item item_2">
        <div class="title">核心指标</div>
        <div class="detail">
          <div
            class="itemDetail"
            v-for="(item, index) in entryData"
            :key="index"
          >
            <div class="label" :class="['icon_' + (index + 1)]">
              {{ item.label }}
            </div>
           <div class="desc">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <div class="item item_3">
        <div class="labelBanner">
          <span :class="{ checked: activeItem === 0 }" @click="changeChart(0)"
            >平均成交周期</span
          >
          <span :class="{ checked: activeItem === 1 }" @click="changeChart(1)"
            >平均战败周期</span
          >
        </div>
    <div
          class="chartDiv"
          :style="`height:${height1}`"
          v-show="activeItem === 0"
          ref="DealRef"
        ></div>
        <div
          class="chartDiv"
          :style="`height:${height2}`"
          v-show="activeItem === 1"
          ref="FailRef"
        ></div>
      </div>

      <div class="item item_5">
        <table class="table" cellspacing="0" cellspadding="0">
         <tr style="background:rgba(4,32,137,0.5)">
            <th class="height60"></th>
            <th>排名</th>
            <th>成交指数</th>
            <th>明确需求</th>
            <th>接受产品</th>
            <th>认可价值</th>
            <th>相信交付</th>
            <th>没有风险</th>
          </tr>
          <tbody v-for="item in list" :key="item.id">
            <tr>
              <td rowspan="4" class="width93" @click="jumpToArea(item)">
                {{ item.name }}
              </td>
              <td rowspan="4">{{ item.rank }}</td>
              <td rowspan="4">  <span :style="getBgColor(item.index,'none')">{{ item.index }}</span></td>
              <th class="height34">明确需求</th>
              <th>接受产品</th>
              <th>认可价值</th>
              <th>相信交付</th>
              <th>没有风险</th>
            </tr>
             <tr>
                   <td
                class="height36"
                @click="showItem(item, '1', '明确需求', $event)"
              >
              <span class="moreInfo" >{{ item.level1 }}</span>
              </td>
              <td @click="showItem(item, '2', '接受产品', $event)">
                <span class="moreInfo">{{ item.level2 }}</span>
              </td>
              <td @click="showItem(item, '3', '认可价值', $event)">
                <span class="moreInfo" >{{ item.level3 }}</span>

              </td>
              <td @click="showItem(item, '4', '相信交付', $event)">
                <span class="moreInfo" >{{ item.level4 }}</span>
              </td>
              <td @click="showItem(item, '5', '没有风险', $event)">
                <span class="moreInfo">{{ item.level5 }}</span>
              </td>
            </tr>
            <tr>
              <th class="height42">跟进量</th>
              <th>爆灯量</th>
              <th>成交量</th>
              <th>战败量</th>
              <th></th>
            </tr>
            <tr>
               <td class="height32"><span >{{ item.count }}</span></td>
              <td><span >{{ item.lnmp }}</span></td>
              <td><span>{{ item.deal }}</span></td>
              <td><span>{{ item.fail }}</span></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
              <alert-item :isItem.sync="isItem" :itemShow="itemShow"></alert-item>
  </div>
</template>
<script>
import { postData } from "@/api/index";
import echarts from "echarts";
import { jump, getLightColor } from "@/utils/index";
import alertItem from "./components/alertItem";
export default {
  data() {
    return {
      entryData: [],
      list: [],
      activeItem: 0,
      itemShow: {},
        isItem: false,
       height1: "auto",
      height2: "auto",
       loading: false,
    };
  },
    components: { alertItem },
  mounted() {
    this.FailChart = echarts.init(this.$refs.FailRef);
    this.DealChart = echarts.init(this.$refs.DealRef);
    this.getDataBySearch();
  },
  methods: {
       showItem(item, level, title,event) {
      
      let items = {};
      let x=event.x-100;
      
      let y=event.y+10;
      this.position={x,y}
      items.a = item["level" + level + "color1"];
      items.a_show = item["level" + level + "color1"]/item["level"+level+"max"];
    
      items.b = item["level" + level + "color2"];
       items.b_show = item["level" + level + "color2"]/item["level"+level+"max"];
      items.c = item["level" + level + "color3"];
       items.c_show = item["level" + level + "color3"]/item["level"+level+"max"];
      items.d = item["level" + level + "color4"];
       items.d_show = item["level" + level + "color4"]/item["level"+level+"max"];
      items.title = title;
      this.isItem = true;
  
      this.itemShow = items;
    },
    async getDataBySearch() {
      this.loading = true;
            const query = {is_mobile:1, ...this.$route.query, ...this.$store.state.query };
      const { data = {} } = await postData(
        "/api/board_deal/region",
        query
      );
      console.log(data);
      const { list, core, deal, fail, date } = data || {};
     

      this.entryData = [
          { label: "成交指数", value: core.index },
        { label: "新增线索量", value: core.count },
        { label: "累计有效线索量", value: core.total },
        { label: "成交量", value: core.deal },
        { label: "战败量", value: core.fail },
      ];

      this.list = list;
      this.deal = formatChart(deal, date);
      this.fail = formatChart(fail, date);
      
     this.height1 =
        Math.max(Math.ceil(this.deal.length / 4 || 1) * 100, 200) + "px";
      this.height2 =
        Math.max(Math.ceil(this.fail.length / 4 || 1) * 100, 200) + "px";
      
      this.$nextTick(() => {
        this.FailChart.resize();
        this.DealChart.resize();
     
        this.FailChart.setOption(getDealOpts(this.fail));
        this.DealChart.setOption(getDealOpts(this.deal));
      });
    
    },
    getColor(v) {
      if (v < 60) {
        return "red";
      }
      if (v >= 80) {
        return "green";
      }
      return "yellow";
    },
     getBgColor(v, mode = null) {
      const { ft } = getLightColor(v);
        return `color:${ft}`;
    },
    changeChart(index) {
      this.activeItem = index;
      this.$nextTick(() => {
        if (index === 1) {
          this.FailChart.resize();
        } else {
          this.DealChart.resize();
        }
      });
    },
    jumpToArea(item) {
        let query={...this.$store.state.query,area_id:item.id};
      jump("/deal/area",this, query);
      // jump("/deal/area?area_id="+item.id,this)
 
    }
    
  },
};
function formatChart(list, date) {
  const data = list.map((o) => ({
    title: o.name,
    dataList: o.value.map((d, j) => ({
      label: date[j],
      value: d, //parseInt(Math.random() * 10) //d TODO
    })),
  }));
  return data;
}

function getDealOpts(list = []) {
  const color = [
    "#f00774",
    "#04ecae",
    "#2cc1ff",
    "#fefc02",
    "#ffad55",
    "#8400ff",
    "#16f7e4",
    "#950247",
  ];
  const legend = list.map((o) => o.title) || [];
  const labelArr = list[0].dataList.map((o) => o && o.label) || [];
  const series =
    list.map((o, i) => ({
      smooth: true,
      name: o.title,
      type: "line",
      data: o.dataList.map((o) => o && o.value),
    })) || [];

  const title = {
    text: "暂无数据",
    left: "center",
    top: "center",
    textStyle: {
      color: "#fff",
      fontSize: 20,
    },
  };
  const option = {
    color,
    title: {
      ...title,
      show: !series.length,
    },
    tooltip: {
      trigger: "axis",
    },
    legend: {
      icon: "roundRect",
      itemWidth: 20,
      itemHeight: 5,
      itemGap: 20,
      left: "5%",
      right: "5%",
      data: legend,
      top: "0px",
      textStyle: {
        color: "#fff",
      },
    },
    calculable: true,
    grid: {
      top: Math.ceil(list.length / 4 || 1) * 50 + "px",
      left: "8%",
      right: "8%",
      bottom: "2%",
      containLabel: true,
    },

    toolbox: {},
    xAxis: {
      type: "category",
      name: "",
      boundaryGap: false,
      data: labelArr,
      axisLabel: {
        textStyle: {
          color: "#fff",
        },
      },
    },
    yAxis: {
      splitNumber: 1,
      nameTextStyle: {
        color: "#fff",
        fontSize: 12,
      },
      name: "（天）",
      type: "value",
      splitLine: { show: false },
      axisLabel: {
        textStyle: {
          color: "#fff",
        },
      },
    },
    series,
  };

  return option;
}
</script>

<style lang="scss" scoped>
@import "@/assets/index.scss";
.container {
  background: url("../../assets/deal_bg2.png");
  background-size: cover;

  background-position: bottom center;
  .content {
    .item {
      display: flex;
      flex-direction: column;
      margin-top: 0.185185rem /* 10/54 */;
      &.item_1 {
        width: 9.666667rem /* 522/54 */;
        height: 7.907407rem /* 427/54 */;
        background: url("../../assets/battle_map.png");

        background-size: cover;
      }
      &.item_2 {
        width: 9.666667rem /* 522/54 */;
        height: 5.240741rem /* 283/54 */;
        background: url("../../assets/board.png");

        background-size: cover;
        .detail::after{
              content: '';
              display: block;
              width: 33.33%; 
        }
        .itemDetail{
          width: 33.33%;
         
        }
      }
       &.item_3 {
       width: 9.666667rem /* 522/54 */;
        // height: 7.851852rem /* 424/54 */;
        // background: url("../../assets/deal_table.png");
        border: 1px solid #144cb9;
        box-shadow: 0px 0px 5px #144cb9 inset;

        background-size: cover;
        .labelBanner {
          height: 1.611111rem /* 87/54 */;
          margin-left: 0.185185rem /* 10/54 */;
          width: 5.259259rem /* 284/54 */;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #fff;

          span {
            width: 2.518519rem /* 136/54 */;
            height: 0.574074rem /* 31/54 */;
            line-height: 0.574074rem /* 31/54 */;
            border: 1px solid #00f2f6;
            border-radius: 0.574074rem /* 31/54 */;
            text-align: center;
            color: #00f2f6;
            font-size: 0.351852rem /* 19/54 */;
            &.checked {
              background: #00f2f6;
              color: #000;
            }
          }
        }
        .chartDiv {
          width: 100%;
          height: 100%;
        }
      }
      &.item_4 {
        width: 9.666667rem /* 522/54 */;
        height: 17.055556rem /* 921/54 */;
        background: url("../../assets/deal_table2.png");
        color: #00d5e9;
        background-size: cover;
        font-size: 0.222222rem /* 12/54 */;

        th {
          max-width: 0.851852rem /* 46/54 */;
        }
      }
      &.item_5 {
        width: 9.666667rem /* 522/54 */;

        border:1px solid #144cb9;
        box-shadow: 0px 0px 5px #144cb9 inset;
        color: #00d5e9;
        background-size: cover;
        font-size: 0.222222rem /* 12/54 */;
     
      }
      .table {
        width: 100%;
        text-align: center;

        tr th,
        tr td {
          padding: 0px;
border: thin solid #00116d;
          &.width93 {
            width: 1.722222rem /* 93/54 */;
          }
          &.height60 {
            height: 1.074074rem /* 58/54 */;
          }
          &.height34 {
            height: 0.62963rem /* 34/54 */;
          }
          &.height36 {
            height: 0.666667rem /* 36/54 */;
          }
          &.height42 {
            height: 0.759259rem /* 41/54 */;
          }
          &.height32 {
            height: 0.555556rem /* 30/54 */;
          }
        }
      }
      .title {
        margin-left: 0.481481rem /* 26/54 */;
        color: #00d5e9;
        font-size: 0.407407rem /* 22/54 */;
        height: 0.962963rem /* 52/54 */;

        line-height: 0.962963rem /* 52/54 */;
      }
      
    }
  }
}
 
</style>