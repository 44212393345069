<template>
  <div class="pDetail" v-if="isItem">
    <div class="pTitle">{{ itemShow.title }}</div>
    <div class="pShow">
      <div class="pItem">
        <div class="pIcon icon_b"></div>
        <div
          class="pHeihgt height_b"
          :style="`height:${itemShow.d_show * 2.081481}rem`"
        ></div>
        <div class="pValue">{{ itemShow.d }}</div>
      </div>
      <div class="pItem">
        <div class="pIcon icon_r"></div>
        <div
          class="pHeihgt height_r"
          :style="`height:${itemShow.c_show * 2.081481}rem`"
        ></div>
        <div class="pValue">{{ itemShow.c }}</div>
      </div>
      <div class="pItem">
        <div class="pIcon icon_y"></div>
        <div
          class="pHeihgt height_y"
          :style="`height:${itemShow.b_show * 2.081481}rem`"
        ></div>
        <div class="pValue">{{ itemShow.b }}</div>
      </div>
      <div class="pItem">
        <div class="pIcon icon_g"></div>
        <div
          class="pHeihgt height_g"
          :style="`height:${itemShow.a_show * 2.081481}rem`"
        ></div>
        <div class="pValue">{{ itemShow.a }}</div>
      </div>
      <div class="pItem">
        <div class="pIcon icon_l"></div>
        <div class="pHeihgt height_l" :style="`height:0px`"></div>
        <div class="pValue">0</div>
      </div>
    </div>
    <div class="close" @click="close"></div>
  </div>
</template>
<script>
export default {
  props: {
    itemShow: Object,
    isItem:Boolean
  },
  methods:{
    close(){
      this.$emit("update:isItem",false);
    }
  }
};
</script>
<style lang="scss" scoped>
.pDetail {
  position: fixed;

  left: 0.851852rem /* 46/54 */;
  width: 8.203704rem /* 443/54 */;
  top: 50%;
  z-index: 20003;
  background: url(../../../assets/board2.png) no-repeat;
  background-size: cover;
  height: 4.388889rem /* 237/54 */;
  padding: 10px;
  color: #00d5e9;
  display: flex;
  flex-direction: column;
  .close {
    position: absolute;
    bottom: -1.111111rem /* 60/54 */;
    left: 3.703704rem /* 200/54 */;
    background: url(../../../assets/close.png) no-repeat;
    background-size: cover;
    width: 0.925926rem /* 50/54 */;
    height: 0.925926rem /* 50/54 */;
  }
  .pTitle {
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .pShow {
    display: flex;

    justify-content: space-around;
    flex: 1;
    .pItem {
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: flex-end;
      .pIcon {
        width: 0.555556rem /* 30/54 */;
        height: 0.481481rem /* 26/54 */;
        // border: 1px solid red;;
        &.icon_r {
          background: url("../../../assets/r.png");
          background-size: cover;
        }
        &.icon_g {
          background: url("../../../assets/g.png");
          background-size: cover;
        }
        &.icon_b {
          background: url("../../../assets/b.png");
          background-size: cover;
        }
        &.icon_y {
          background: url("../../../assets/y.png");
          background-size: cover;
        }
        &.icon_l {
          background: url("../../../assets/l.png");
          background-size: cover;
        }
      }
      .pHeihgt {
        &.height_r {
          background: url("../../../assets/r_h.png");
          background-size: cover;
        }
        &.height_g {
          background: url("../../../assets/g_h.png");
          background-size: cover;
        }
        &.height_b {
          background: url("../../../assets/b_h.png");
          background-size: cover;
        }
        &.height_y {
          background: url("../../../assets/y_h.png");
          background-size: cover;
        }
        &.height_l {
          background: url("../../../assets/l_h.png");
          background-size: cover;
        }
      }
      .pValue {
        height: 0.37037rem /* 20/54 */;
      }
    }
  }
}
</style>